import { StepStatus } from "@allica/ui-react";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { ApplicationSections, Stage } from "src/pages/business-rewards/BusinessRewards.types";
import ApplicantUI from "src/shared/applicant/ApplicantUI";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";

const isPhaseTwo = isFeatureActive(FeatureFlag.BRA_PHASE_TWO);

const BusinessRewardsApplicant = () => {
  const {
    businessRewardsData,
    setCurrentStage,
    updateStepperConfig,
    setShowGenericError,
    setBusinessRewardsData,
    alertStages,
    setAlertStages,
    stepOrder,
  } = useBusinessRewardsContext();

  const updateDataAndStepper = (updatedApplicationSectionData: ApplicationSections) => {
    setBusinessRewardsData({
      ...businessRewardsData,
      businessRewardsApplicationSections: updatedApplicationSectionData,
    });

    const stage = isPhaseTwo ? Stage.DEPOSIT : Stage.SUMMARY;
    const nextStep = stepOrder.includes(Stage.PARTIES) ? Stage.PARTIES : stage;

    const nextStepStatus =
      nextStep === Stage.PARTIES && alertStages.includes(Stage.PARTIES)
        ? StepStatus.ALERT
        : StepStatus.INCOMPLETE;

    setCurrentStage(nextStep);
    updateStepperConfig([
      { stage: Stage.APPLICANT, value: { status: StepStatus.COMPLETE } },
      { stage: nextStep, value: { status: nextStepStatus, disabled: false } },
    ]);
  };

  return (
    <ApplicantUI
      businessApplicationSections={businessRewardsData.businessRewardsApplicationSections}
      applicationID={businessRewardsData.applicationID}
      setShowGenericError={setShowGenericError}
      alertStages={alertStages}
      setAlertStages={setAlertStages}
      updateDataAndStepper={updateDataAndStepper}
    />
  );
};

export default BusinessRewardsApplicant;

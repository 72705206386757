import { Text } from "@allica/ui-react";
import { Modal } from "src/components/modal/Modal";

interface UnableToVerifyModalProps {
  isOpen: boolean;
  onClose: () => void;
  continueAction: () => void;
  continueActionLoading: boolean;
}

export const UnableToVerifyModal = ({
  isOpen,
  onClose,
  continueAction,
  continueActionLoading,
}: UnableToVerifyModalProps) => {
  return (
    <Modal
      headerTitle="Unable to check details"
      primaryButtonText="Continue"
      secondaryButtonText="Edit details"
      isOpen={isOpen}
      onClose={onClose}
      primaryAction={continueAction}
      primaryButtonLoading={continueActionLoading}
      secondaryAction={onClose}
      variant="alert"
      closeOnOverlayClick={false}
      heapId={{
        primaryAction: "continue-nocheck-button",
        secondaryAction: "edit-account-button",
      }}
    >
      <Text as="p" textStyle="body-03" color="$text-02">
        We can’t confirm that the name matches the account number and sort code. Double-check that
        you have the details right and only continue if you’re sure they are correct.
        <br />
        <br />
        If you continue with these details your application will be reviewed by our team.
      </Text>
    </Modal>
  );
};

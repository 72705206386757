import { StepStatus } from "@allica/ui-react";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";
import { Stage, UpdateStepperConfigStage } from "../../BusinessRewards.types";
import { FormProvider, useForm } from "react-hook-form";
import CompanyUI from "src/shared/company/CompanyUI";
import {
  CompanyErrorFields,
  CompanyFormValues,
  CompanySectionType,
} from "src/shared/company/Company.types";
import { getBackendDataStructure } from "src/shared/company/Company.utils";

import { defaultApplicantSectionData } from "src/shared/applicant/Applicant.utils";
import { DepositsAPI } from "src/core/service";
import { useEffect } from "react";
import { ApplicationStatusResponse } from "src/core/service/deposits-api/DepositsApi.types";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { getAlertAndStepperStages } from "./BusinessRewardsCompany.utils";

const BusinessRewardsCompany = () => {
  const methods = useForm<CompanyFormValues>({
    mode: "onChange",
    defaultValues: {
      addresses: [],
      expectedAnnualTurnover: "",
      taxResidencies: [],
      numberOfEmployees: "",
    },
  });

  const {
    stepperConfig,
    businessRewardsData,
    setBusinessRewardsData,
    setCurrentStage,
    setShowGenericError,
    updateStepperConfig,
    setAlertStages,
    alertStages,
    updateOtherPartiesStage,
  } = useBusinessRewardsContext();

  const {
    response: savedResponse,
    status: saveReqStatus,
    request: saveData,
    error: saveError,
  } = DepositsAPI<ApplicationStatusResponse, CompanyErrorFields>(
    `applications/businesses/${businessRewardsData?.applicationID}/company`,
  );

  useEffect(() => {
    if (saveReqStatus.success) {
      const oldCompanyHouseNumber =
        businessRewardsData?.businessRewardsApplicationSections?.companySection?.companyHouseNumber;
      const newData = { ...businessRewardsData };
      const values = methods?.getValues();
      const connectedIndividuals =
        values.companiesHouseResponse.businessProfile.connectedIndividuals;

      if (
        isFeatureActive(FeatureFlag.OTHER_PARTIES) &&
        connectedIndividuals &&
        updateOtherPartiesStage
      ) {
        updateOtherPartiesStage(connectedIndividuals, newData);
      }

      const stepperStages: UpdateStepperConfigStage[] = [
        { stage: Stage.BUSINESS, value: { status: StepStatus.COMPLETE } },
      ];
      const newCompanyHouseNumber = values?.companyHouseNumber;
      const companyHasChanged =
        !!oldCompanyHouseNumber && newCompanyHouseNumber !== oldCompanyHouseNumber;

      if (companyHasChanged) {
        newData.businessRewardsApplicationSections.applicantSection = defaultApplicantSectionData;
        const { updatedAlertStages, updatedStepperStages } = getAlertAndStepperStages(
          stepperConfig,
          alertStages,
        );

        stepperStages.push(...updatedStepperStages);
        if (updatedAlertStages.length) setAlertStages([...alertStages, ...updatedAlertStages]);
      } else if (stepperConfig.applicant.status === StepStatus.INACTIVE) {
        stepperStages.push({ stage: Stage.APPLICANT, value: { status: StepStatus.INCOMPLETE } });
      }

      newData.cobApplicationId = savedResponse?.cobApplicationId || "";
      newData.businessRewardsApplicationSections.companySection = {
        ...newData?.businessRewardsApplicationSections.companySection,
        ...getBackendDataStructure(values),
      } as CompanySectionType;
      setBusinessRewardsData(newData);

      setCurrentStage(Stage.APPLICANT);
      updateStepperConfig(stepperStages, companyHasChanged);
    }
  }, [saveReqStatus.success]);

  return (
    <FormProvider {...methods}>
      <CompanyUI
        saveData={saveData}
        saveError={saveError}
        companySectionData={businessRewardsData?.businessRewardsApplicationSections?.companySection}
        saveReqStatus={saveReqStatus}
        setShowGenericError={setShowGenericError}
        stepperConfig={stepperConfig}
        applicationSource="PAYMENT"
      />
    </FormProvider>
  );
};

export default BusinessRewardsCompany;

import { getTextStyle } from "@allica/ui-react";
import { Flex, Link } from "@chakra-ui/react";
import { LinkIcon } from "../../icon";
import { TaskChecklistItemProps } from "../TaskChecklist.types";
import { TaskChecklistStatusIcon } from "../task-checklist-status-icon/TaskChecklistStatusIcon";

export const TaskChecklistItem = ({
  label,
  href,
  onClick,
  position,
  status,
  taskId,
  heapId
}: TaskChecklistItemProps) => {
  let borderRadius = "0";
  if (position) {
    borderRadius = position === "first" ? "0.4rem 0.4rem 0 0" : "0 0 0.4rem 0.4rem";
  }

  const handleClick = () => onClick(taskId);

  return (
    <Flex
      borderRadius={borderRadius}
      alignItems="flex-start"
      justifyContent="space-between"
      p={{ base: "2rem 1.6rem" }}
      backgroundColor="$ui-02"
      w="100%"
    >
      <TaskChecklistStatusIcon status={status} />
      <Link
        display="flex"
        justifyContent="space-between"
        w="100%"
        color="$link-01"
        sx={getTextStyle("body-03")}
        href={href}
        target="_blank"
        data-heapid={heapId}
        onClick={handleClick}
      >
        {label}
        <LinkIcon boxSize="2.4rem" ml="2.4rem" />
      </Link>
    </Flex>
  );
};

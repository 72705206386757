import { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Box } from "@chakra-ui/react";
import { Heading, Radio, RadioGroup, Text } from "@allica/ui-react";
import { FormGroup } from "src/components/input-set/FormGroup";
import { TaxResidencyCard } from "./TaxResidencyCard";
import { FormValues, TaxResidencyProps } from "./TaxResidency.types";

const TaxResidency = ({ taxDescription, label, hideHeading, ...styleProps }: TaxResidencyProps) => {
  const { control, getValues, setError, clearErrors } = useFormContext<FormValues>();
  const { fields, append, remove } = useFieldArray({ name: "taxResidencies", control });
  const [changedCountry, setChangeCountry] = useState("");

  const [taxStatus, setTaxStatus] = useState("0");

  useEffect(() => {
    const taxResidenciesLength = getValues("taxResidencies")?.length;
    const status = taxResidenciesLength > 0 ? "1" : "0";
    setTaxStatus(status);
  }, []);

  useEffect(() => {
    if (taxStatus === "1" && fields.length < 1) {
      append({ taxCountryCode: "", number: "" });
    }
  }, [taxStatus]);

  useEffect(() => {
    if (fields.length < 1) {
      setTaxStatus("0");
    } else {
      setTaxStatus("1");
    }
  }, [fields]);

  const handleStatusChange = (value: string) => {
    if (value === "0") {
      remove();
    }
    setTaxStatus(value);
  };

  const handleAddTaxResidency = (taxResidencyNumber: number) => {
    clearErrors(`taxResidencies.${taxResidencyNumber + 1}`);
    const currentCountryCode = getValues(`taxResidencies.${taxResidencyNumber}.taxCountryCode`);
    const currentTaxNumber = getValues(`taxResidencies.${taxResidencyNumber}.number`);

    if (currentCountryCode && currentTaxNumber) {
      append({ taxCountryCode: "", number: "" });
    }
    if (!currentCountryCode) {
      setError(`taxResidencies.${taxResidencyNumber}.taxCountryCode`, {
        message: "Please select a country from the list",
      });
    }
    if (!currentTaxNumber) {
      setError(`taxResidencies.${taxResidencyNumber}.number`, {
        message: "Please add a valid tax identification number",
      });
    }
  };

  const handleDeleteTaxInfo = (taxResidencyNumber: number) => {
    remove(taxResidencyNumber);
    setChangeCountry("");
  };

  const handleCountryChange = (e: Event) => {
    const { value } = e?.target as HTMLInputElement;
    setChangeCountry(value);
  };

  return (
    <Box {...styleProps}>
      {!hideHeading && (
        <Heading textStyle="heading-05" color="$text-01" mb="1.6rem">
          Tax Status
        </Heading>
      )}
      {taxDescription && (
        <Text textStyle="body-03" color="$text-02" mb="3.2rem">
          {taxDescription}
        </Text>
      )}

      <FormGroup label={label || "Are you a taxpayer outside the UK?"} isRequired>
        <RadioGroup onChange={handleStatusChange} value={taxStatus}>
          <Radio value="0">No</Radio>
          <Radio value="1">Yes</Radio>
        </RadioGroup>
      </FormGroup>

      {taxStatus === "1" &&
        fields?.map((item, index) => (
          <TaxResidencyCard
            key={item.id}
            taxResidencyNumber={index}
            taxResidencies={fields}
            handleAddTaxResidency={handleAddTaxResidency}
            handleDeleteTaxInfo={handleDeleteTaxInfo}
            handleCountryChange={handleCountryChange}
            changedCountry={changedCountry}
          />
        ))}
    </Box>
  );
};
export default TaxResidency;

import { TrackingParameterKey, TrackingParameters } from "./tracking.types";

export const getCookie = (name: string) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = document.cookie.match(regex);
  if (match) {
    return match[2];
  }
};

export const TRACKING_PARAMETERS: TrackingParameterKey[] = [
  "utm_source",
  "utm_medium",
  "utm_content",
  "utm_campaign",
  "utm_term",
  "gclid",
  "fbclid",
  "_fbc",
  "_fbp",
];

export const removeLeadingUnderscore = (value: string): string => {
  if (value.startsWith("_")) return value.slice(1);
  return value;
};

export const getCookiesObject = (cookies: string[]) => {
  return cookies.reduce<Record<string, string>>((prev, curr) => {
    const value = getCookie(curr);
    if (!value) return prev;
    const key = removeLeadingUnderscore(curr);
    return { ...prev, [key]: value };
  }, {});
};

export const getUtmParametersFromCookies = () => getCookiesObject(TRACKING_PARAMETERS);

export const getTrackingParamsFromUrl = (searchParams: URLSearchParams): TrackingParameters => {
  const params = Object.fromEntries(searchParams);
  return Object.keys(params).reduce((prev, curr) => {
    if (TRACKING_PARAMETERS.includes(curr as TrackingParameterKey)) {
      const key = removeLeadingUnderscore(curr);
      return { ...prev, [key]: params[curr] };
    }
    return prev;
  }, {});
};

export const setUtmInSessionStorage = (trackingParameters: TrackingParameters) => {
  sessionStorage.setItem("utm", JSON.stringify(trackingParameters));
};

export const getUtmFromSessionStorage = (): TrackingParameters | null => {
  const utmString = sessionStorage.getItem("utm");
  if (utmString) return JSON.parse(utmString);
  return null;
};

import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getTrackingParamsFromUrl, setUtmInSessionStorage } from "../utils/tracking/tracking";

export function useSetUtmParamsInSessionStorage() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const trackingParameters = getTrackingParamsFromUrl(searchParams);
    if (Object.keys(trackingParameters).length) {
      setUtmInSessionStorage(trackingParameters);
    }
  }, []);
}

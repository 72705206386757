import { VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { TaskChecklistStatus, TaskChecklistProps } from "./TaskChecklist.types";
import { TaskChecklistItem } from "./task-checklist-item/TaskChecklistItem";

export const TaskChecklist = ({
  items,
  onComplete,
  status,
  displayErrors,
  verifyTaskComplete,
}: TaskChecklistProps) => {
  const count = items.length;
  const [checklistItemStatus, setChecklistItemStatus] = useState(
    status ||
      items.reduce<TaskChecklistStatus>(
        (prev, curr) => ({ ...prev, [curr.taskId]: "incomplete" }),
        {}
      )
  );

  const handleClick = (taskId: string) => {
    if (!verifyTaskComplete || verifyTaskComplete(taskId)) {
      setChecklistItemStatus({ ...checklistItemStatus, [taskId]: "complete" });
    } else {
      setChecklistItemStatus({ ...checklistItemStatus, [taskId]: "error" });
    }
  };

  useEffect(() => {
    for (const item in checklistItemStatus) {
      if (checklistItemStatus[item] !== "complete") return;
    }

    onComplete();
  }, [checklistItemStatus]);

  useEffect(() => {
    if (displayErrors) {
      const statuses = { ...checklistItemStatus };
      for (const item in statuses) {
        if (statuses[item] !== "complete") {
          statuses[item] = "error";
        }
      }
      setChecklistItemStatus(statuses);
    }
  }, [displayErrors]);

  return (
    <VStack spacing="0.2rem" mb="4.8rem">
      {items.map((item, index) => {
        return (
          <TaskChecklistItem
            key={item.taskId}
            taskId={item.taskId}
            href={item.href}
            label={item.label}
            heapId={item.heapId}
            position={index === 0 ? "first" : index === count - 1 ? "last" : undefined}
            onClick={handleClick}
            status={checklistItemStatus[item.taskId]}
          />
        );
      })}
    </VStack>
  );
};

import { IndividualDetailsType } from "src/shared/company/Company.types";
import { Stage } from "../../BusinessRewards.types";
import {
  MIN_SIGNIFICANT_INDIVIDUALS,
  MAX_SIGNIFICANT_INDIVIDUALS,
  PARTIES_STEP_ORDER_INDEX,
} from "./businessRewardsContext.constants";

/**
 * Determines if the other parties step should be shown based on the number
 * of significant individuals (determined by the BE) connected to a company.
 *
 * @param connectedIndividuals - individuals connected to the company (data from companies house)
 */
export const shouldOtherPartiesStepBeShown = (
  connectedIndividuals: IndividualDetailsType[],
): boolean => {
  const individualsLength = connectedIndividuals.length;
  return (
    individualsLength >= MIN_SIGNIFICANT_INDIVIDUALS &&
    individualsLength <= MAX_SIGNIFICANT_INDIVIDUALS
  );
};
/**
 * Determines a new `stepOrder` based on given parameters. Returns `null` if there is no change.
 *
 * @param stepOrder - list of stages (Stage[])
 * @param hasPartiesStep - does the stepOrderInclude Stage.PARTIES
 * @param shouldAddParties - should the stepOrder include Stage.PARTIES
 */
export const getStepOrder = ({
  stepOrder,
  hasPartiesStep,
  shouldAddParties,
}: {
  stepOrder: Required<Stage>[];
  hasPartiesStep: boolean;
  shouldAddParties: boolean;
}): Stage[] | null => {
  if (hasPartiesStep && !shouldAddParties) {
    return stepOrder.filter((stage) => stage !== Stage.PARTIES);
  } else if (!hasPartiesStep && shouldAddParties) {
    const newStepOrder = [...stepOrder];
    newStepOrder.splice(PARTIES_STEP_ORDER_INDEX, 0, Stage.PARTIES);
    return newStepOrder;
  }

  return null;
};

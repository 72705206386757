import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  ChevronRightIcon,
  ErrorIcon,
  Heading,
  StepStatus,
  Text,
} from "@allica/ui-react";
import { useBusinessRewardsContext } from "../../context/BusinessRewardsContext";
import { Stage } from "../../BusinessRewards.types";
import TaxResidency from "src/components/tax-residency/TaxResidency";
import { PercentageInput } from "src/components/input/percentage/PercentageInput";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { FormGroup } from "src/components/input-set/FormGroup";
import {
  Box,
  Card,
  FormControl,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Link,
  Select,
  Stack,
} from "@chakra-ui/react";
import MaskedInput from "src/components/masked-input/MaskedInput";
import { BaseInput } from "src/components/input/default/Input";
import { dateValidate, moveUKToFirstPosition } from "src/core/utils";
import { useStore } from "src/core/store/StoreContext";
import Nationality from "src/components/Nationality/Nationality";
import AddressWrapper from "src/components/address/address-wrapper/AddressWrapper";
import { useState } from "react";
import InternationalPhoneInput from "src/components/input/international-phone/InternationalPhoneInput";
import Checkbox from "src/components/checkbox/Checkbox";
import { getDefaultDescription } from "src/shared/applicant/Applicant.utils";
import { formatDateToDisplay } from "src/shared/company/Company.utils";
import { OtherPartiesFormValues } from "./BusinessRewardsOtherParties.types";
import { validatePhoneNumber } from "src/components/input/international-phone/utils/InternationalPhone.utils";

export const BusinessRewardsOtherParties = () => {
  const {
    setCurrentStage,
    updateStepperConfig,
    businessRewardsData,
    setBusinessRewardsData,
    setAlertStages,
    alertStages,
  } = useBusinessRewardsContext();
  const referenceData = useStore();
  const [showManualAdd, setShowManualAdd] = useState(
    !!businessRewardsData.businessRewardsApplicationSections.otherPartiesSection,
  );
  const [showAddNationality, setShowAddNationality] = useState(true);
  const countries = moveUKToFirstPosition(referenceData?.countries);

  const mainApplicantNodeId =
    businessRewardsData.businessRewardsApplicationSections.applicantSection!.nodeId;
  const connectedIndividuals =
    businessRewardsData.businessRewardsApplicationSections.companySection.companiesHouseResponse
      .businessProfile.connectedIndividuals;

  // considers only one "other party"
  const [otherParty] = connectedIndividuals!.filter(
    (individual) => individual.nodeId !== mainApplicantNodeId,
  );
  const { nationalities, taxIdentifications, dateOfBirthSCV, ...rest } = otherParty;

  const methods = useForm<OtherPartiesFormValues>({
    defaultValues: businessRewardsData.businessRewardsApplicationSections.otherPartiesSection || {
      ...rest,
      role: rest?.businessIndividualRelations
        ?.map((relation: Record<string, string>) =>
          getDefaultDescription(relation?.type, referenceData.businessIndividualRelationTypes),
        )
        .join(" / "),
      dateOfBirthSCV: dateOfBirthSCV ? formatDateToDisplay(dateOfBirthSCV) : "",
      countries: rest.internationalTradingCountryIsoCodes?.map((c: string) => ({ value: c })) || [],
      nationalities: nationalities?.map((item) => ({ value: item })) as { value: string }[],
      taxResidencies: taxIdentifications?.filter((item) => item?.taxCountryCode !== "GB"),
      phone: "",
      emailAddress: "",
    },
  });

  const {
    register,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
  } = methods;

  const { onChange: dateOfBirthRefOnchange, ...restReg } = register("dateOfBirthSCV", {
    required: "Please enter a date of birth",
    validate: dateValidate,
  });

  const onSubmit: SubmitHandler<OtherPartiesFormValues> = () => {
    setBusinessRewardsData({
      ...businessRewardsData,
      businessRewardsApplicationSections: {
        ...businessRewardsData.businessRewardsApplicationSections,
        otherPartiesSection: getValues(), // will have to map values for request payload (for example taxResidencies => taxIdentifications)
      },
    });

    const updatedSteps = [
      { stage: Stage.PARTIES, value: { status: StepStatus.COMPLETE } },
      { stage: Stage.DEPOSIT, value: { status: StepStatus.INCOMPLETE } },
    ];

    setCurrentStage(Stage.DEPOSIT);
    setAlertStages([...alertStages.filter((stage) => stage !== "parties")]);
    updateStepperConfig(updatedSteps);
  };

  return (
    <>
      <Heading color="$text-01" size="h1" as="h1" mb="24px" mt="64px">
        Other parties
      </Heading>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Stack gap="6.4rem" mt="6.4rem">
            <Stack gap="3.2rem">
              <Heading color="$text-01" textStyle="heading-04">
                Personal Details
              </Heading>
              <FormGroup label="First name" isRequired>
                <BaseInput
                  {...register("firstName")}
                  isInvalid={false}
                  isDisabled
                  sx={{ WebkitTextFillColor: "#1A3660" }}
                />
              </FormGroup>
              <FormGroup label="Last name" isRequired>
                <BaseInput
                  isInvalid={false}
                  {...register("lastName")}
                  isDisabled
                  sx={{ WebkitTextFillColor: "#1A3660" }}
                />
              </FormGroup>
              <FormGroup label="Role" isRequired>
                <BaseInput
                  isInvalid={false}
                  {...register("role")}
                  isDisabled
                  sx={{ WebkitTextFillColor: "#1A3660" }}
                />
              </FormGroup>
              <FormGroup
                label="Date of birth"
                isRequired
                info={<Text>DD/MM/YYYY</Text>}
                error={errors.dateOfBirthSCV?.message}
              >
                <InputGroup width="24rem">
                  <MaskedInput
                    maskedPattern="**/**/****"
                    maskedChar="*"
                    validate={(value: string) => /^\d+$/?.test(value)}
                    onChangeHandler={(e) => {
                      dateOfBirthRefOnchange(e);
                    }}
                    {...restReg}
                  />
                  <InputRightElement>
                    {!!errors?.dateOfBirthSCV && <ErrorIcon color="$danger-01" />}
                  </InputRightElement>
                </InputGroup>
              </FormGroup>
              <FormGroup label="Country of birth" isRequired error={errors.countryOfBirth?.message}>
                <Select
                  {...register("countryOfBirth", {
                    required: "Please select a country of birth",
                  })}
                  placeholder="Please select"
                >
                  {countries?.map((item) => (
                    <option key={item?.name} value={item?.name}>
                      {item?.description}
                    </option>
                  ))}
                </Select>
              </FormGroup>
              <Nationality
                showAddNationality={showAddNationality}
                setShowAddNationality={setShowAddNationality}
              />
              <FormGroup isRequired label="Home Address">
                <Card
                  variant="outline"
                  bgColor="$ui-01"
                  px="3.2rem"
                  mt={"1.6rem"}
                  paddingTop={"2.4rem"}
                  paddingBottom={"4rem"}
                >
                  <AddressWrapper
                    addressTypeLabel={""}
                    addressHelperText={"Start typing your address or postcode"}
                    showManualAdd={showManualAdd}
                    setShowManualAdd={setShowManualAdd}
                  />
                </Card>
              </FormGroup>
            </Stack>
            <Stack gap="3.2rem">
              <Heading color="$text-01" textStyle="header-04">
                Financial details
              </Heading>
              <FormGroup
                label="Shareholding"
                error={errors.shareHoldingPercentage?.message}
                isRequired
              >
                <Controller
                  control={control}
                  name="shareHoldingPercentage"
                  rules={{
                    required: "Please enter shareholding from 0-100",
                    min: {
                      value: 0,
                      message: "Please enter shareholding from 0-100",
                    },
                    max: {
                      value: 100,
                      message: "Please enter shareholding from 0-100",
                    },
                  }}
                  render={({ field }) => (
                    <PercentageInput
                      w="15.2rem"
                      isInvalid={!!errors?.shareHoldingPercentage}
                      {...field}
                    />
                  )}
                />
              </FormGroup>

              <TaxResidency hideHeading label="Is the individual a taxpayer outside the UK?" />
            </Stack>
            <Stack gap="3.2rem">
              <Heading color="$text-01" textStyle="header-04">
                Contact details
              </Heading>
              <FormGroup label="Email" isRequired error={errors?.emailAddress?.message}>
                <BaseInput
                  {...register("emailAddress", {
                    required: "Please enter a valid email address",
                    pattern: {
                      value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: "Please enter a valid email address",
                    },
                  })}
                  type="email"
                  isInvalid={!!errors?.emailAddress}
                />
              </FormGroup>
              <FormGroup label="Phone" error={errors?.phone?.message} isRequired>
                <Controller
                  control={control}
                  rules={{
                    required: "Please enter a valid phone number",
                    validate: validatePhoneNumber,
                  }}
                  name="phone"
                  render={({ field }) => (
                    <InternationalPhoneInput isInvalid={!!errors?.phone} {...field} />
                  )}
                />
              </FormGroup>
              <Alert status="info">
                <AlertIcon />
                <Box>
                  <AlertDescription textStyle="body-03" mb="1.6rem">
                    All information held at Companies House must be accurate before applying. If
                    it’s not accurate, please contact Companies House and update on their system.
                  </AlertDescription>
                  <Link
                    color="$interactive-01"
                    textStyle="body-02-medium"
                    fontWeight="600"
                    textDecoration="none"
                    variant="plain"
                    target="_blank"
                    href="https://www.gov.uk/government/organisations/companies-house"
                  >
                    Go to Companies House
                  </Link>
                </Box>
              </Alert>
              <FormControl isInvalid={!!errors.confirmation}>
                {/* https://github.com/react-hook-form/react-hook-form/discussions/6838#discussioncomment-2093357 */}
                <Controller
                  control={control}
                  name="confirmation"
                  render={({ field: { ref, ...rest } }) => (
                    <Checkbox {...rest}>
                      I confirm all information at Companies House shown on this page is accurate
                    </Checkbox>
                  )}
                  rules={{
                    required: "Please confirm all information is accurate",
                  }}
                />
                <FormErrorMessage mt="1.2rem" mb="2.4rm">
                  <>{errors?.confirmation && errors?.confirmation.message}</>
                </FormErrorMessage>
              </FormControl>
            </Stack>
          </Stack>
          <Button
            isLoading={false} // update with BE integration
            loadingText="Save and continue"
            spinnerPlacement="end"
            type="submit"
            float="right"
            mt="8rem"
            padding="2.4rem 3.2rem"
            rightIcon={<ChevronRightIcon />}
          >
            Save and continue
          </Button>
        </form>
      </FormProvider>
    </>
  );
};

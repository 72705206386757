import { Steps, StepStatus } from "@allica/ui-react";
import { useState, createContext, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AlertStage,
  BusinessRewardsContextProviderType,
  BusinessRewardsContextType,
  BusinessRewardsDataTypes,
  BusinessRewardsApiType,
  Stage,
  SubmittedStages,
  UpdateStepperConfig,
  CurrentApplicationStage,
} from "../BusinessRewards.types";
import {
  businessRewardsInitialStepperConfig,
  businessRewardsInitialStepperConfigMVP,
} from "./businessRewardsInitialStepperConfig";
import { appInsights } from "src/core/app/ApplicationInsights";
import { initialBusinessRewardsData } from "./initialBusinessRewardsData";
import {
  businessRewardsCompletedStepperConfig,
  businessRewardsCompletedStepperConfigMVP,
} from "./businessRewardsCompletedStepperConfig";
import { mapBusinessRewardsStepper } from "./utils/mapBusinessRewardsStepper";
import {
  parseAccountActivityAnswers,
  parseBcaConfiguration,
} from "../stages/account-activity/BusinessRewardsAccountActivity.utils";
import { DepositsAPI } from "src/core/service";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { businessRewardsStepOrder, businessRewardsStepOrderMVP } from "./businessRewardsStepOrder";
import { IndividualDetailsType } from "src/shared/company/Company.types";
import { getStepOrder, shouldOtherPartiesStepBeShown } from "./utils/businessRewardsContext.utils";

const initialStepperConfig = isFeatureActive(FeatureFlag.BRA_PHASE_TWO)
  ? businessRewardsInitialStepperConfig
  : (businessRewardsInitialStepperConfigMVP as Steps<Stage>);

const completedStepperConfig = isFeatureActive(FeatureFlag.BRA_PHASE_TWO)
  ? businessRewardsCompletedStepperConfig
  : (businessRewardsCompletedStepperConfigMVP as Steps<Stage>);

const businessRewardsPageUrl = (stage: string | undefined) => {
  const baseURL = "/business/current-account";
  return stage ? `${baseURL}/${stage}` : baseURL;
};

const isPhaseTwo = isFeatureActive(FeatureFlag.BRA_PHASE_TWO);
const stepOrder = (isPhaseTwo ? businessRewardsStepOrder : businessRewardsStepOrderMVP) as Stage[];

const BusinessRewardsContext = createContext<BusinessRewardsContextType>({
  businessRewardsData: initialBusinessRewardsData,
  setBusinessRewardsData: () => null,
  currentStage: null,
  setCurrentStage: () => null,
  stepperConfig: initialStepperConfig as Steps<Stage>,
  updateStepperConfig: () => null,
  stepOrder,
  updateOtherPartiesStage: () => null,
  showGenericError: false,
  setShowGenericError: () => null,
  alertStages: [],
  setAlertStages: () => null,
  isBusinessRewardsDataLoading: false,
});

const BusinessRewardsContextProvider = ({
  children,
  applicationID,
  applicationIDStatus,
}: BusinessRewardsContextProviderType) => {
  const location = useLocation();
  const stage = location.pathname.split("/").reverse().filter(Boolean)[0] as Stage;
  const navigateTo = useNavigate();

  const [currentStage, setCurrentStage] = useState<Stage | null>(null);
  const [stepperConfig, setStepperConfig] = useState<Steps<Stage>>(initialStepperConfig);
  const [businessRewardsData, setBusinessRewardsData] = useState<BusinessRewardsDataTypes>(
    initialBusinessRewardsData,
  );
  const [showGenericError, setShowGenericError] = useState<boolean>(false);
  const [incompleteStages, setIncompleteStages] = useState<AlertStage[]>([]);
  const [stepOrder, setStepOrder] = useState<Stage[]>(businessRewardsStepOrder);

  const {
    response: getApplicationResponse,
    status: getApplicationStatus,
    request: getApplication,
  } = DepositsAPI<BusinessRewardsApiType>(`applications/businesses/${applicationID}/ALL`);

  useEffect(() => {
    if (applicationID) getApplication();
  }, [applicationID]);

  const updateOtherPartiesStage = (
    connectedIndividuals: IndividualDetailsType[],
    businessRewardsData: BusinessRewardsDataTypes,
  ) => {
    const shouldAddParties = shouldOtherPartiesStepBeShown(connectedIndividuals);
    const hasPartiesStep = stepOrder.includes(Stage.PARTIES);

    if (!hasPartiesStep && !shouldAddParties) return; // nothing to clean up - return early

    const newStepOrder = getStepOrder({ stepOrder, hasPartiesStep, shouldAddParties });
    if (newStepOrder) setStepOrder(newStepOrder);

    businessRewardsData.businessRewardsApplicationSections.otherPartiesSection = null;
  };

  const updateStepperConfig: UpdateStepperConfig = (stages, disableCompleteOverride = false) => {
    const newStepperConfig = { ...stepperConfig };
    for (const { stage, value } of stages) {
      if (!disableCompleteOverride && stepperConfig[stage].status === StepStatus.COMPLETE) {
        value.status = StepStatus.COMPLETE;
      }
      newStepperConfig[stage] = { ...newStepperConfig[stage], ...value };
    }
    setStepperConfig(newStepperConfig);
  };

  useEffect(() => {
    if (!stage || !Object.values(Stage).includes(stage as Stage)) {
      setCurrentStage(null);
      updateStepperConfig([{ stage: Stage.SIGN_UP, value: { status: StepStatus.INACTIVE } }], true);
    }
    if (Object.values(Stage).includes(stage as Stage) && stage !== currentStage) {
      setCurrentStage(stage as Stage);
    }
  }, [location]);

  useEffect(() => {
    if (currentStage && stage !== currentStage) {
      appInsights.trackPageView({
        name: currentStage ? "bra-" + currentStage : "bra-privacy-begin",
      });

      navigateTo(businessRewardsPageUrl(currentStage));
    }
  }, [currentStage]);

  useEffect(() => {
    if (getApplicationStatus.error) {
      appInsights.trackEvent({ name: `business-rewards-error-on-save-and-continue` });
      throw new Error("Unable to get the current business rewards application stage");
    }

    if (getApplicationStatus.success) {
      const {
        currentApplicationStage: currentAppStage,
        cobApplicationId,
        accountSection,
        ...applicationSections
      } = getApplicationResponse;

      const alertStagesList: AlertStage[] = [];
      const currentApplicationStage =
        currentAppStage === ("COMPANY" as CurrentApplicationStage) ? "BUSINESS" : currentAppStage;

      !getApplicationResponse?.applicantSection?.firstName && alertStagesList.push(Stage.APPLICANT);

      const parsedAccountSectionFormValues = accountSection?.fullAccountQuestionnaireDetails
        ? parseAccountActivityAnswers(accountSection.fullAccountQuestionnaireDetails.questions)
        : initialBusinessRewardsData.businessRewardsApplicationSections.accountSection!;

      let questions = null;
      if (accountSection?.fullAccountQuestionnaireDetails) {
        questions = parseBcaConfiguration(accountSection.fullAccountQuestionnaireDetails.questions);
      }

      const newBusinessRewardsData: BusinessRewardsDataTypes = {
        applicationID: applicationID ?? "",
        currentApplicationStage,
        cobApplicationId,
        questions,
        businessRewardsApplicationSections: {
          ...businessRewardsData.businessRewardsApplicationSections,
          ...applicationSections,
          accountSection: parsedAccountSectionFormValues,
        },
      };

      appInsights.trackEvent({ name: `business-rewards-save-and-continue` });
      setBusinessRewardsData(newBusinessRewardsData);

      if (currentApplicationStage) {
        if (Object.keys(Stage).includes(currentApplicationStage)) {
          const { stepperConfig, currentStep, alertStages } = mapBusinessRewardsStepper(
            Stage[currentApplicationStage as keyof typeof Stage],
            alertStagesList,
            stepOrder,
          );
          setIncompleteStages(alertStages);
          setStepperConfig(stepperConfig);
          setCurrentStage(currentStep);
        } else if (Object.keys(SubmittedStages).includes(currentApplicationStage)) {
          setStepperConfig(completedStepperConfig);
        }
      }
    }
  }, [getApplicationStatus]);

  const isBusinessRewardsDataLoading =
    applicationIDStatus.loading ||
    ((applicationIDStatus.success || !!applicationID) &&
      !businessRewardsData.currentApplicationStage) ||
    getApplicationStatus.loading;

  return (
    <BusinessRewardsContext.Provider
      value={{
        businessRewardsData,
        setBusinessRewardsData,
        currentStage,
        setCurrentStage,
        stepperConfig,
        updateStepperConfig,
        stepOrder,
        updateOtherPartiesStage,
        showGenericError,
        setShowGenericError,
        alertStages: incompleteStages,
        setAlertStages: setIncompleteStages,
        isBusinessRewardsDataLoading,
      }}
    >
      {children}
    </BusinessRewardsContext.Provider>
  );
};

const useBusinessRewardsContext = () => {
  const context = useContext(BusinessRewardsContext);
  if (!context) {
    throw new Error(
      "BusinessRewardsContext missing. Check that is wrapped with BusinessRewardsContextProvider",
    );
  }
  return context;
};

export { useBusinessRewardsContext, BusinessRewardsContextProvider };

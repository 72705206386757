import { Steps, StepStatus } from "@allica/ui-react";
import { AlertStage, Stage, UpdateStepperConfigStage } from "../../BusinessRewards.types";

export const getAlertAndStepperStages = (
  stepperConfig: Steps<Stage>,
  currentAlertStages: AlertStage[],
) => {
  const updatedAlertStages: AlertStage[] = [];
  const updatedStepperStages: UpdateStepperConfigStage[] = [];

  if (stepperConfig.applicant.status === StepStatus.COMPLETE) {
    updatedStepperStages.push({ stage: Stage.APPLICANT, value: { status: StepStatus.ALERT } });
    if (!currentAlertStages.includes(Stage.APPLICANT)) updatedAlertStages.push(Stage.APPLICANT);
  } else if (stepperConfig.applicant.status === StepStatus.INACTIVE) {
    updatedStepperStages.push({ stage: Stage.APPLICANT, value: { status: StepStatus.INCOMPLETE } });
  }

  if (stepperConfig.parties.status === StepStatus.COMPLETE) {
    updatedStepperStages.push({
      stage: Stage.PARTIES,
      value: { status: StepStatus.ALERT, disabled: true },
    });
    if (!currentAlertStages.includes(Stage.PARTIES)) updatedAlertStages.push(Stage.PARTIES);
  } else if (stepperConfig.parties.status === StepStatus.INCOMPLETE) {
    updatedStepperStages.push({ stage: Stage.PARTIES, value: { status: StepStatus.INACTIVE } });
  }

  return {
    updatedAlertStages,
    updatedStepperStages,
  };
};

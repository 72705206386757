import { AlertIcon, AlertDescription, Alert } from "@allica/ui-react";
import { Box, FormControl, FormErrorMessage } from "@chakra-ui/react";

import IndividualDetails from "src/pages/business-savings/shared/individual-details/IndividualDetails";
import { Controller, useFormContext } from "react-hook-form";

import Checkbox from "src/components/checkbox/Checkbox";

const MainApplicantDetails = () => {
  const { control, formState } = useFormContext();

  return (
    <>
      <IndividualDetails isMainApplicant />
      <Alert status="info" mb="3.2rem" mt="3.2rem">
        <AlertIcon />
        <Box>
          <AlertDescription>
            All information held at Companies House must be accurate before applying. If it’s not
            accurate, please contact Companies House and update on their system. Go to companies
            House
          </AlertDescription>
        </Box>
      </Alert>
      <FormControl isInvalid={!!formState?.errors.confirmation}>
        {/* https://github.com/react-hook-form/react-hook-form/discussions/6838#discussioncomment-2093357 */}
        <Controller
          control={control}
          name="confirmation"
          render={({ field: { ref, ...rest } }) => (
            <Checkbox {...rest} data-heapid="confirmation-accurate-checkbox">
              I confirm all information at Companies House is accurate
            </Checkbox>
          )}
          rules={{
            required: "Please confirm all information is accurate",
          }}
        />
        <FormErrorMessage mt="1.2rem" mb="2.4rm" data-heapid="confirmation-empty-error">
          <>{formState?.errors?.confirmation && formState?.errors?.confirmation.message}</>
        </FormErrorMessage>
      </FormControl>
    </>
  );
};

export default MainApplicantDetails;

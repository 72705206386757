import { Steps, StepStatus } from "@allica/ui-react";
import { Stage, StageMVP } from "../BusinessRewards.types";

export const businessRewardsInitialStepperConfigMVP: Steps<StageMVP> = {
  [StageMVP.SIGN_UP]: {
    label: "Sign up",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [StageMVP.VERIFY]: {
    label: "Verification",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [StageMVP.BUSINESS]: {
    label: "Business details",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [StageMVP.APPLICANT]: {
    label: "Main applicant",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [StageMVP.SUMMARY]: {
    label: "Summary",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
};

export const businessRewardsInitialStepperConfig: Steps<Stage> = {
  [Stage.SIGN_UP]: {
    label: "Sign up",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.VERIFY]: {
    label: "Verification",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.BUSINESS]: {
    label: "Business details",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.APPLICANT]: {
    label: "Main applicant",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.PARTIES]: {
    label: "Other parties",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.DEPOSIT]: {
    label: "Deposit amount",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.ACCOUNT]: {
    label: "Activity",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.DOCUMENT]: {
    label: "Documents",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
  [Stage.SUMMARY]: {
    label: "Summary",
    disabled: false,
    status: StepStatus.INACTIVE,
  },
};

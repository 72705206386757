import {
  Button,
  CheckboxGroup,
  ChevronRightIcon,
  getHeadingStyle,
  getTextStyle,
  Heading,
  StepStatus,
  Text,
} from "@allica/ui-react";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { TaskChecklist } from "src/components/task-checklist/TaskChecklist";
import { DepositsAPI } from "src/core/service";
import { useStore } from "src/core/store/StoreContext";
import { FeatureFlag } from "src/environments/feature.flags";
import { DocumentFormValues, DocumentSection } from "src/shared/documents/Documents.types";
import Checkbox from "../../../../components/checkbox/Checkbox";
import { usePersonalContext } from "../../context/PersonalContext";
import { Stage } from "../../PersonalSavings.types";

const Documents = () => {
  const {
    handleSubmit,
    setError,
    control,
    getValues,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm<DocumentFormValues>();

  const { importantDocuments } = useStore();

  const documentsLinks = importantDocuments?.reduce((acc: Record<string, string>, item) => {
    acc[item?.name] = item?.description;
    return acc;
  }, {});

  const {
    stepperConfig,
    personalSavingData,
    setCurrentStage,
    setStepperConfig,
    setPersonalSavingData,
    setShowGenericError,
    selectedProductInfo,
  } = usePersonalContext();

  const [allDocumentsRead, setAllDocumentsRead] = useState(
    // prevent requirement to click all three documents in dev and prep
    isFeatureActive(FeatureFlag.DOCUMENT_SKIP_LINKS) ||
      personalSavingData?.individualApplicationSections?.documentSection?.terms ||
      false,
  );
  const [displayChecklistErrors, setDisplayChecklistErrors] = useState(false);

  const {
    status: documentStatus,
    request: saveDocumentData,
    error: documentError,
  } = DepositsAPI(`applications/individuals/${personalSavingData?.applicationID}/document`);

  const onSubmit = (data: DocumentFormValues) => {
    setShowGenericError(false);
    const { documentTerms, marketingPreferences } = data;
    const payload: Record<string, boolean> = {
      terms: documentTerms?.includes("terms") ? true : false,
      email: false,
      mail: false,
      sms: false,
      phone: false,
    };
    if (marketingPreferences && marketingPreferences?.length > 0) {
      marketingPreferences?.forEach((item: string) => {
        payload[item] = true;
      });
    }
    if (personalSavingData?.applicationID) {
      saveDocumentData({
        method: "PATCH",
        body: JSON.stringify(payload),
      });
    }
  };

  useEffect(() => {
    const marketingPreferences = [];
    const documentTerms = [];
    const data: Record<string, boolean> =
      personalSavingData?.individualApplicationSections?.documentSection || {};
    for (const key in data) {
      if (key === "terms" && data?.[key]) {
        documentTerms.push(key);
      } else if (key && data?.[key]) {
        marketingPreferences.push(key);
      }
    }
    reset({ documentTerms, marketingPreferences });
  }, [personalSavingData?.individualApplicationSections?.documentSection]);

  useEffect(() => {
    if (documentStatus.success) {
      const { marketingPreferences, documentTerms } = getValues();
      const newData: DocumentSection = {
        terms: documentTerms?.includes("terms") ? true : false,
      };
      if (marketingPreferences && marketingPreferences.length > 0) {
        marketingPreferences?.forEach((item: string) => {
          newData[item as keyof DocumentSection] = true;
        });
      }

      const newPersonalSavingData = { ...personalSavingData };
      newPersonalSavingData.individualApplicationSections.documentSection = { ...newData };
      setPersonalSavingData(newPersonalSavingData);

      const newState = { ...stepperConfig };
      newState[Stage.DOCUMENT].status = StepStatus.COMPLETE;
      if (newState[Stage.SUMMARY].status !== StepStatus.COMPLETE) {
        newState[Stage.SUMMARY].status = StepStatus.INCOMPLETE;
      }
      setStepperConfig(newState);
      setCurrentStage(Stage.SUMMARY);
    }
  }, [documentStatus.success]);

  useEffect(() => {
    if (documentStatus.error) {
      if (documentError.code === "VALIDATION_ERROR") {
        documentError?.errors?.forEach((data) => {
          const { field, reason } = data;
          if (field === "terms") {
            setError("documentTerms", { message: reason });
          } else {
            setError("marketingPreferences", { message: reason });
          }
        });
      } else {
        setShowGenericError(true);
      }
    }
  }, [documentStatus.error]);

  return (
    <>
      <Heading color="$text-01" size="h1" as="h1" mb="1.6rem" mt="6.4rem">
        Important documents
      </Heading>
      <Text color="$text-03" textStyle="body-03" mb="6.4rem">
        Please open and review the following documents.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TaskChecklist
          items={[
            {
              taskId: "productSummary",
              label: "Product Summary",
              href: selectedProductInfo?.pdfUrl || "https://allica.bank/404",
              heapId: "product-summary-link",
            },
            {
              taskId: "termsAndConditions",
              label: "Personal Savings Terms and Conditions",
              href: documentsLinks?.["TERMS_AND_CONDITIONS"],
              heapId: "terms-conditions-link",
            },
            {
              taskId: "infoSheet",
              label: "FSCS Info Sheet and Exclusion List",
              href: documentsLinks?.["FCS_INFO_SHEET"],
              heapId: "fscs-info-link",
            },
          ]}
          onComplete={() => {
            const { documentTerms } = getValues();
            if (!allDocumentsRead && documentTerms?.length) {
              clearErrors("documentTerms");
            }
            setAllDocumentsRead(true);
          }}
          displayErrors={displayChecklistErrors}
          status={
            allDocumentsRead
              ? {
                  productSummary: "complete",
                  termsAndConditions: "complete",
                  infoSheet: "complete",
                }
              : undefined
          }
        />
        <FormControl mb="8rem" isInvalid={!!errors.documentTerms}>
          <Controller
            control={control}
            name="documentTerms"
            render={({ field: { ref, ...rest } }) => (
              <CheckboxGroup {...rest}>
                <Checkbox
                  ref={ref}
                  value={"terms"}
                  alignItems="flex-start"
                  data-heapid="read-and-understood"
                >
                  I have read and understood the Product Summary and FSCS Info Sheet and Exclusion
                  List and read, understood and agree to be bound by the Terms and Conditions.
                </Checkbox>
              </CheckboxGroup>
            )}
            rules={{
              validate: (value) => {
                if (!allDocumentsRead || !value.length) {
                  setDisplayChecklistErrors(true);
                  return "Please open, read and accept the above documents";
                }
              },
            }}
          />
          <FormErrorMessage mt="1.2rem" mb="2.4rm" data-heapid="not-accepted-error">
            <>{errors.documentTerms && errors.documentTerms.message}</>
          </FormErrorMessage>
        </FormControl>

        <Heading sx={getHeadingStyle("heading-04")} color="$text-01" mb="1.6rem">
          Marketing Preferences
        </Heading>
        <Text sx={getTextStyle("body-03")} color="$text-02" mb="4rem">
          If you would like to receive information about our other products and services and receive
          news, insights and information about events from Allica, please choose how you would like
          us to communicate with you below.{" "}
        </Text>

        <Text sx={getTextStyle("body-03")} color="$text-02" mb="4rem">
          You can unsubscribe from these communications at any time. For more information on how to
          manage your marketing preferences, our privacy practices, and how we are committed to
          protecting and respecting your privacy, please read our Privacy Policy.
        </Text>
        <FormControl mb="1.6rem" isInvalid={!!errors.marketingPreferences}>
          <FormLabel>
            Choose how we communicate with you.
            <Text ml="0.4rem" color="$text-03" as="abbr">
              (Optional)
            </Text>
          </FormLabel>
          <Controller
            control={control}
            name="marketingPreferences"
            render={({ field: { ref, ...rest } }) => (
              <CheckboxGroup {...rest}>
                <Checkbox value="email" data-heapid="marketing-preferences-button">
                  Email
                </Checkbox>
                <Checkbox value="phone" data-heapid="marketing-preferences-button">
                  Phone
                </Checkbox>
                <Checkbox value="sms" data-heapid="marketing-preferences-button">
                  Sms
                </Checkbox>
                <Checkbox value="mail" data-heapid="marketing-preferences-button">
                  Mail
                </Checkbox>
              </CheckboxGroup>
            )}
          />
          <FormErrorMessage mt="1.2rem" mb="2.4rm">
            <>{errors.marketingPreferences && errors?.marketingPreferences?.message}</>
          </FormErrorMessage>
        </FormControl>

        <Button
          isLoading={documentStatus?.loading}
          loadingText="Save and continue"
          spinnerPlacement="end"
          type="submit"
          float="right"
          padding="2.4rem 3.2rem"
          rightIcon={<ChevronRightIcon />}
          data-heapid="save-continue-button"
        >
          Save and continue
        </Button>
      </form>
    </>
  );
};

export default Documents;

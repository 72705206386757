import { useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { Button, ChevronRightIcon, Heading, StepStatus, Text } from "@allica/ui-react";
import { SubmitHandler, useForm } from "react-hook-form";

import { FormGroup } from "src/components/input-set/FormGroup";
import { CurrencyInput } from "src/components/input/currency/CurrencyInput";
import {
  formatInputCurrency,
  validateDepositAmount,
} from "src/components/input/currency/CurrencyInput.utils";
import { BcaProductsAPI, BcaProductsType, DepositsAPI } from "src/core/service";
import { DepositErrorFields } from "src/core/service/deposits-api/DepositsApi.types";
import { DepositFormValues } from "src/shared/deposit/Deposit.types";
import { usePersonalContext } from "../../context/PersonalContext";
import { Stage } from "../../PersonalSavings.types";
import { Modal } from "../../../../components/modal/Modal";
import { isFeatureActive } from "src/components/feature-toggle/FeatureToggle";
import { FeatureFlag } from "src/environments/feature.flags";
import { depositDataHeapId } from "src/shared/deposit/Deposit.utils";

const showInterestRate = isFeatureActive(FeatureFlag.SHOW_PERSONAL_SAVINGS_INTEREST_RATE);

const Deposit = () => {
  const { isOpen: showModal, onOpen: openModal, onClose: closeModal } = useDisclosure();

  const {
    stepperConfig,
    setCurrentStage,
    setStepperConfig,
    personalSavingData,
    setPersonalSavingData,
    setShowGenericError,
    selectedProductInfo,
  } = usePersonalContext();

  const {
    handleSubmit,
    register,
    setError,
    getValues,
    formState: { errors },
  } = useForm<DepositFormValues>();

  const {
    status: saveReqStatus,
    request: saveData,
    error: saveError,
  } = DepositsAPI<null, DepositErrorFields>(
    `applications/individuals/${personalSavingData?.applicationID}/deposit`,
  );

  const { response: productsResponse, request: getProducts } = BcaProductsAPI<BcaProductsType>();
  const { rate } = productsResponse.productDetails || {};

  const onSubmit: SubmitHandler<DepositFormValues> = (values) => {
    setShowGenericError(false);
    const { depositAmount } = values;

    if (showInterestRate && isEasyAccess && Number(depositAmount) >= 100000) {
      openModal();
      return;
    }

    onSaveData();
  };

  const onSaveData = () => {
    const depositAmount = getValues("depositAmount");

    const payload = { depositAmount, depositDuration: "NA" };
    saveData({
      method: "PATCH",
      body: JSON.stringify(payload),
    });
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (saveReqStatus.success) {
      const newData = { ...personalSavingData };
      const depositAmount = getValues("depositAmount");
      const values = {
        ...newData.individualApplicationSections.depositSection,
        depositAmount,
      };
      newData.individualApplicationSections.depositSection = values;
      setPersonalSavingData(newData);

      const newState = { ...stepperConfig };
      newState[Stage.DEPOSIT].status = StepStatus.COMPLETE;
      if (
        newState[Stage.ACCOUNT].status !== StepStatus.COMPLETE &&
        newState[Stage.ACCOUNT].status !== StepStatus.ALERT
      ) {
        newState[Stage.ACCOUNT].status = StepStatus.INCOMPLETE;
      }
      setStepperConfig(newState);

      setCurrentStage(Stage.ACCOUNT);
    }
  }, [saveReqStatus.success]);

  useEffect(() => {
    if (saveReqStatus.error) {
      if (saveError.code === "VALIDATION_ERROR") {
        saveError?.errors?.forEach((data) => {
          const { field, reason } = data;
          setError(field, { message: reason });
        });
      } else setShowGenericError(true);
    }
  }, [saveReqStatus.error]);

  const minFormattedCurrency = formatInputCurrency(selectedProductInfo.minimumDeposit);
  const maxFormattedCurrency = formatInputCurrency(selectedProductInfo.maximumDeposit);

  const isEasyAccess = selectedProductInfo.subType === "DEPOSIT_INSTANT_ACCESS";
  return (
    <>
      {showInterestRate && (
        <Modal
          headerTitle={`How does ${rate}% AER variable sound?`}
          primaryButtonText="Got it"
          primaryAction={onSaveData}
          isOpen={showModal}
          onClose={closeModal}
          newHeadingStyle={true}
        >
          <Text as="p" textStyle="body-03" color="$text-02">
            If you’re looking to deposit more than £100,000 we have other accounts that might be
            more suitable and we will contact you.
          </Text>
          <br />
          <Text as="p" textStyle="body-03" color="$text-02" mb="112px">
            Money in our Personal Rewards Account's Savings Pot earns {rate}% AER variable and comes
            with contact details for a dedicated relationship manager. We look forward to discussing
            it with you.
          </Text>
        </Modal>
      )}
      <Heading color="$text-01" size="h1" as="h1" mb="24px" mt="64px">
        Deposit amount
      </Heading>
      <Text color="$text-02" textStyle="body-02-bold" mb="16px">
        How much are you planning to put into your account?
      </Text>
      <Text color="$text-03" textStyle="body-03" mb="16px">
        To benefit from the interest rate, you have to deposit a minimum of £10,000 within 14 days
        of the account being opened. If you do not, the account will be closed and your money
        returned with no interest added.
      </Text>
      <Text color="$text-03" textStyle="body-03" mb="64px">
        We'll send you details on how to fund the account if your application is successful.
      </Text>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup
          label="Deposit amount"
          info={`Amount must be between ${minFormattedCurrency} and ${maxFormattedCurrency}.`}
          error={errors?.depositAmount?.message}
          data-heapid={depositDataHeapId(errors?.depositAmount?.type)}
          isRequired
        >
          <CurrencyInput
            w="23.8rem"
            value={personalSavingData?.individualApplicationSections?.depositSection?.depositAmount}
            {...register("depositAmount", {
              required: "Please add an expected deposit amount",
              setValueAs: (value) => Number(value.replace(/,|£/g, "")),
              validate: validateDepositAmount(
                isEasyAccess,
                selectedProductInfo.minimumDeposit,
                selectedProductInfo.maximumDeposit,
              ),
            })}
            isInvalid={!!errors?.depositAmount}
            data-heapid="deposit-amount-enter"
          />
        </FormGroup>

        <Button
          isLoading={saveReqStatus.loading}
          loadingText="Save and continue"
          spinnerPlacement="end"
          type="submit"
          float="right"
          mt="8rem"
          padding="2.4rem 3.2rem"
          rightIcon={<ChevronRightIcon />}
          data-heapid="save-continue-button"
        >
          Save and continue
        </Button>
      </form>
    </>
  );
};

export default Deposit;
